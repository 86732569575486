import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Typography,
  Stack,
  TextField,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { TermsTable } from '../../components/Tables/TermsTable';
import { getAllTerms, postTerm, updateTerm, deleteTerm } from '../../api/request';

export function TermsManagement() {
  const [terms, setTerms] = useState([]);
  const [formData, setFormData] = useState({ abbreviation: '', full_word: '' });
  const [editingRow, setEditingRow] = useState(null);
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 5 });
  const [confirmDialog, setConfirmDialog] = useState({ open: false, record: null });

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const data = await getAllTerms();
        setTerms(Array.isArray(data) ? data : []);
      } catch (err) {
        const errorMessage = err.response?.status === 404
          ? 'Error 404: Resource not found.'
          : 'An unexpected error occurred.';
        toast.error(errorMessage);
      }
    };

    fetchTerms();
  }, []);

  const resetForm = () => setFormData({ abbreviation: '', full_word: '' });

  const handleApiError = (error, defaultMessage) => {
    toast.error(error.response?.data?.message || defaultMessage);
    console.error(error);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddRecord = async () => {
    if (!formData.abbreviation || !formData.full_word) {
      toast.warn('Por favor llena ambos campos.');
      return;
    }

    try {
      const response = await postTerm(formData);
      if (response.status === 201) {
        const { term_id } = response.data;
        setTerms((prev) => [...prev, { id: term_id, ...formData }]);
        resetForm();
        toast.success('Registro agregado con éxito.');
      }
    } catch (error) {
      handleApiError(error, 'Error al conectar con el servidor.');
    }
  };

  const handleConfirmDelete = (record) => {
    setConfirmDialog({ open: true, record });
  };

  const handleDelete = async () => {
    const { record } = confirmDialog;
    if (!record) return;

    try {
      const response = await deleteTerm(record.id);
      if (response.status === 200) {
        const updatedTerms = terms.filter((term) => term.id !== record.id);

        // Actualizar términos y paginación
        setTerms(updatedTerms);
        const maxPage = Math.max(0, Math.ceil(updatedTerms.length / pagination.rowsPerPage) - 1);
        if (pagination.page > maxPage) setPagination((prev) => ({ ...prev, page: maxPage }));

        toast.success('Registro eliminado con éxito.');
      } else {
        toast.warn('No se encontró el registro a eliminar.');
      }
    } catch (error) {
      handleApiError(error, 'Error al eliminar el registro.');
    } finally {
      setConfirmDialog({ open: false, record: null });
    }
  };

  const handleEditChange = (e, field, recordId) => {
    const { value } = e.target;
    setTerms((prev) =>
      prev.map((term) => (term.id === recordId ? { ...term, [field]: value } : term))
    );
  };

  const handleSave = async (record) => {
    try {
      const response = await updateTerm(record.id, {
        abbreviation: record.abbreviation,
        full_word: record.full_word,
      });

      if (response.status === 200) {
        toast.success('Registro actualizado con éxito.');
        setEditingRow(null);
      } else {
        toast.error('Error al actualizar el registro.');
      }
    } catch (error) {
      handleApiError(error, 'Error al conectar con el servidor.');
    }
  };

  const handleCancel = () => setEditingRow(null);

  const handleChangePage = (event, newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  return (
    <Stack spacing={2} sx={{ padding: 3 }}>
      <Typography variant="h5" component="h1" textAlign="center" color="#10486d">
        Gestión de Términos
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, mb: 2, mt: 1.5 }}>
        <TextField
          label="Abreviación"
          name="abbreviation"
          value={formData.abbreviation}
          onChange={handleInputChange}
          variant="outlined"
          size="small"
          fullWidth
        />
        <TextField
          label="Palabra Completa"
          name="full_word"
          value={formData.full_word}
          onChange={handleInputChange}
          variant="outlined"
          size="small"
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleAddRecord} sx={{ width: 160 }}>
          Agregar
        </Button>
      </Box>

      <TermsTable
        terms={terms}
        editingRow={editingRow}
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        handleEdit={(record) => setEditingRow(record.id)}
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleEditChange={handleEditChange}
        handleDelete={handleConfirmDelete}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {/* Confirm Dialog */}
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, record: null })}
      >
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar el registro "{confirmDialog.record?.abbreviation}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ open: false, record: null })} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
