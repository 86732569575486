import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Paper, Popper, styled, Collapse } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

const StyledDropdown = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "4px",
  boxShadow: theme.shadows[5],
  marginTop: "4px",
}));

export function NestedList(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const CustomListItemText = styled(ListItemText)(() => ({
    "& .MuiTypography-root": {
      fontSize: "0.85rem",
    },
  }));

  const handleMouseEnter = (event) => {
    if (!props.drawerOpen) {
      setAnchorEl(event.currentTarget);
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!props.drawerOpen) {
      setAnchorEl(null);
      setIsDropdownOpen(false);
    }
  };

  const handleClick = () => {
    if (props.drawerOpen) {
      setIsCollapseOpen((prev) => !prev);
    }
  };

  return (
    <Box onMouseLeave={handleMouseLeave}>
      <Tooltip title={props.tooltip} disableInteractive disableHoverListener={props.drawerOpen}>
        <ListItemButton
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
        >
          <ListItemIcon>
            <props.mainIcon />
          </ListItemIcon>
          {props.drawerOpen && <CustomListItemText primary={props.mainTitle} />}
          {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>

      {!props.drawerOpen && (
        <Popper
          open={isDropdownOpen}
          anchorEl={anchorEl}
          placement="right-start"
          style={{ zIndex: 1300 }}
        >
          <StyledDropdown onMouseLeave={handleMouseLeave}>
            {React.Children.map(props.children, (child) =>
              React.cloneElement(child, {
                onClick: () => {
                  if (child.props.onClick) child.props.onClick();
                  setIsDropdownOpen(false);
                },
              })
            )}
          </StyledDropdown>
        </Popper>
      )}

      {props.drawerOpen && (
        <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
          <Box sx={{ pl: 2 }}>
            {React.Children.map(props.children, (child) => (
              <Box onClick={child.props.onClick}>{child}</Box>
            ))}
          </Box>
        </Collapse>
      )}
    </Box>
  );
}

NestedList.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
  drawerOpen: PropTypes.bool.isRequired,
};
