

export const Auth = {
    tokenAuth: () => localStorage.getItem('_token_auth'),

    isAuthenticated: () => {
      if (Auth.tokenAuth() === null || Auth.tokenAuth() === undefined) return false
      return true
      
    },
    getUser: () => {
      let user = localStorage.getItem('user')
      user = JSON.parse(user)
      
      if (user === undefined || user == null ) return null
      return user
      
    },
    authenticate: (token) => {
      sessionStorage.clear()
      localStorage.clear()
      
      localStorage.setItem('_token_auth', token.access_token)
      sessionStorage.setItem('initial_info', token.initial_info)
      localStorage.setItem('user', JSON.stringify(token.user))
    },
    logout: () => {
      sessionStorage.clear()
      localStorage.clear()
    },
    
  }

  