import React from "react";
import { Box, Typography } from "@mui/material";

export function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#10486D",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 2,
        mt: "auto",
      }}
    >
      <Typography variant="body2" align="center">
        Powered by Schneider Electric
      </Typography>
    </Box>
  );
}
