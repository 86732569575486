import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

export function TermsTable({
  terms,
  editingRow,
  page,
  rowsPerPage,
  handleEdit,
  handleSave,
  handleCancel,
  handleEditChange,
  handleDelete,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Abreviación</b></TableCell>
              <TableCell><b>Palabra Completa</b></TableCell>
              <TableCell><b>Acciones</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {terms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => (
              <TableRow key={record.id}>
                {/* Celda de Abreviación */}
                <TableCell>
                  {editingRow === record.id ? (
                    <TextField
                      value={record.abbreviation}
                      onChange={(e) =>
                        handleEditChange(e, 'abbreviation', record.id)
                      }
                      size="small"
                    />
                  ) : (
                    record.abbreviation
                  )}
                </TableCell>

                {/* Celda de Palabra Completa */}
                <TableCell>
                  {editingRow === record.id ? (
                    <TextField
                      value={record.full_word}
                      onChange={(e) =>
                        handleEditChange(e, 'full_word', record.id)
                      }
                      size="small"
                    />
                  ) : (
                    record.full_word
                  )}
                </TableCell>

                {/* Acciones */}
                <TableCell>
                  {editingRow === record.id ? (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleSave(record)}
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton color="secondary" onClick={handleCancel}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(record)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(record)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginación */}
      <TablePagination
        component="div"
        count={terms.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Registros por página"
      />
    </>
  );
}

TermsTable.propTypes = {
    terms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        abbreviation: PropTypes.string.isRequired,
        full_word: PropTypes.string.isRequired,
      })
    ).isRequired,
    editingRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleEditChange: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
  };