import * as React from "react";
import { useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { mainListItems } from "../../components/SideBarItems/ListItems";
import { AppBar } from "../../utils/Material Styles/AppBar/AppBar";
import { DrawerCustom } from "../../utils/Material Styles/Drawer/Drawer";
import Drawer from '@mui/material/Drawer';
import { ProfileButtonMenu } from "../ButtonWithMenu/ProfileMenuButton";
import { Footer } from "../Footer/Footer";
import logo from "../../assets/images/logo-blanco-cenit.png";

const mdTheme = createTheme();

export function Layout(props) {
  const [open, setOpen] = React.useState(false);
  const [openMobile, setOpenMobile] = React.useState(false); 
  const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleMobileDrawer = () => {
    setOpenMobile(!openMobile);
  };

  React.useEffect(() => {
    if (isMobile) {
      setOpen(false);
    } else {
      setOpenMobile(false);
    }
  }, [isMobile]);
  

  return (
    <ThemeProvider theme={mdTheme}>
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />

      {/* Contenedor del AppBar y Drawer */}
      <Box sx={{ display: "flex", flex: "1 0 auto" }}>
        {/* Drawer superpuesto en mobile */}
        {isMobile ? (
          <Drawer
            open={openMobile}
            onClose={toggleMobileDrawer}
          >
            <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", px: [1] }}>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            {/* Acá estan los botones que se muestran en el menú lateral */}
            <List component="nav">
              {mainListItems(isMobile ? openMobile : open)}
              <Divider sx={{ my: 1 }} />
            </List>
          </Drawer>
        ) : (
          <DrawerCustom
            variant={"permanent"}
            open={open}
            sx={{
              "& .MuiDrawer-paper": {
                position: isMobile ? "absolute" : "relative",
              },
            }}
          >
            <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", px: [1] }}>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            {/* Acá estan los botones que se muestran en el menú lateral */}
            <List component="nav">
              {mainListItems(isMobile ? openMobile : open)}
              <Divider sx={{ my: 1 }} />
            </List>
          </DrawerCustom>
        )}

        {/* Contenido principal */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {/* AppBar */}
          <AppBar position="absolute" open={open} sx={{ backgroundColor: "#10486D" }}>
            <Toolbar sx={{ pr: "24px" }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={isMobile ? toggleMobileDrawer : toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={logo}
                alt="Logo Schneider"
                style={{ height: 40, width: "auto", marginLeft: "9px", marginRight: "20px" }}
              />
              {(isMobile && !open) || !isMobile ? (
                <>
                  <Typography component="h1" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                    {props.title}
                  </Typography>
                  <ProfileButtonMenu isDrawerOpen={open} isMobile={isMobile} />
                </>
              ) : null}
            </Toolbar>
          </AppBar>

          <Toolbar /> {/* Espaciado para evitar que el contenido quede debajo del AppBar */}

          <Container maxWidth={false} sx={{ mt: 4, mb: 4, p: 0, width: "100%" }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
              {props.children}
            </Paper>
          </Container>
          {/* Footer */}
          <Footer />
        </Box>
      </Box>
    </Box>

    </ThemeProvider>
  );
}
