import { useReducer, useEffect, useState, useRef } from "react";
import { RecordView } from "../../components/Recording/RecordingComponent.js";
import { ChatUI } from "../../components/Chat/Chat.js";
import { DateRangeCalendar } from "../../components/DateRange/DateRangeCalendar.js";
import { getUserMessages, processText, audioToText } from "../../api/request";
import { Box, TextField, Container } from "@mui/material";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from "@mui/icons-material/Cancel";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const messageReducer = (state = [], action) => {
    switch (action.type) {
        case "ADD_MESSAGE":
            return [...state, action.payload];
        case "REMOVE_MESSAGE":
            return state.filter((message) => message.id !== action.payload);
        case "RESET_MESSAGES":
            return [];
        case "SET_MESSAGES":
            return action.payload;
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export function VirtualAssistant() {
    const [initDate, setInitDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formData, setFormData] = useState({ abbreviation: "" });
    const [messages, dispatch] = useReducer(messageReducer, []);
    const [searchResults, setSearchResults] = useState([]);
    const [currentResultIndex, setCurrentResultIndex] = useState(0);
    const chatEndRef = useRef(null);
    const typingMessageId = "typing";

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const previousMessages = await getUserMessages();
                dispatch({
                    type: "SET_MESSAGES",
                    payload: Array.isArray(previousMessages) ? previousMessages : [],
                });
            } catch (err) {
                toast.error(err.message || "Ocurrió un error inesperado.");
            }
        };

        document.title = "Asistente Digital";

        fetchMessages();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const formatToCreatedAt = (date, isEndOfDay = false) => {
        if (isEndOfDay) {
            date.setHours(23, 59, 59, 999);
        } else {
            date.setHours(0, 0, 0, 0);
        }
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    
    const handleSearch = () => {
        const term = formData.abbreviation.trim().toLowerCase();
        if (!term) {
            toast.error("Por favor, ingresa una palabra para buscar.");
            return;
        }
    
        if (initDate > endDate) {
            toast.error("La fecha inicial no puede ser posterior a la fecha final.");
            return;
        }
    
        const normalizedInitDate = formatToCreatedAt(new Date(initDate));
        const normalizedEndDate = formatToCreatedAt(new Date(endDate), true);
    
        const results = messages.filter((message) => {
            // Extraer y normalizar la fecha del mensaje
            const messageDate = new Date(message.created_at).toISOString();
    
            // Comparar dentro del rango
            const isWithinDateRange =
                messageDate >= normalizedInitDate && messageDate <= normalizedEndDate;
    
            // Coincidencia de texto y rango de fechas
            return message.text.toLowerCase().includes(term) && isWithinDateRange;
        });
    
        if (results.length === 0) {
            toast.info("No se encontraron coincidencias.");
            setCurrentResultIndex(0);
            return;
        }
    
        setSearchResults(results);
        setCurrentResultIndex(0);
        toast.success(`${results.length} coincidencias encontradas.`);
    };

    const handleNextResult = () => {
        if (searchResults.length > 0) {
            setCurrentResultIndex((prevIndex) => (prevIndex + 1) % searchResults.length);
        }
    };

    const handlePreviousResult = () => {
        if (searchResults.length > 0) {
            setCurrentResultIndex(
                (prevIndex) => (prevIndex - 1 + searchResults.length) % searchResults.length
            );
        }
    };

    const addTypingIndicator = () => {
        if (!messages.find((msg) => msg.id === typingMessageId)) {
            const typingMessage = {
                id: typingMessageId,
                sent: false,
                isTyping: true,
            };
            dispatch({ type: "ADD_MESSAGE", payload: typingMessage });
        }
    };

    const removeTypingIndicator = () => {
        dispatch({ type: "REMOVE_MESSAGE", payload: typingMessageId });
    };

    const handleNewMessage = async (inputMessage) => {
        if (!inputMessage?.text?.trim()) {
            toast.error("No se puede enviar un mensaje vacío.");
            return;
        }

        const userMessage = {
            id: uuidv4(),
            created_at: new Date().toISOString(),
            sent: true,
            text: inputMessage.text,
        };

        dispatch({ type: "ADD_MESSAGE", payload: userMessage });
        addTypingIndicator();

        try {
            const processFormData = new FormData();
            processFormData.append("text", inputMessage.text);

            const ia_response = await processText(processFormData);

            removeTypingIndicator();

            if (ia_response.status === 200) {
                const { ia_response: iaJsonResponse, audio_base64 } = ia_response.data;

                const assistantMessage = {
                    id: iaJsonResponse._id,
                    created_at: iaJsonResponse.created_at,
                    sent: false,
                    text: iaJsonResponse.ia_response.ia_response || "No se recibió respuesta de texto.",
                    audio_base64: audio_base64,
                };

                dispatch({ type: "ADD_MESSAGE", payload: assistantMessage });
            } else {
                toast.error("Hubo un problema procesando tu mensaje. Por favor, intenta nuevamente.");
            }
        } catch (error) {
            console.error("Error al procesar el texto:", error);
            toast.error("No se pudo procesar el mensaje.");
            removeTypingIndicator();
        }
    };

    const handleAudioMessage = async (audioBlob) => {
        try {
            const audioFile = new File([audioBlob], "audio.mp3", { type: "audio/mpeg" });
            const formData = new FormData();
            formData.append("audio", audioFile);

            const apiResponse = await audioToText(formData);

            if (apiResponse.status === 200) {
                const responseText = apiResponse.data;
                handleNewMessage(responseText);
            } else {
                toast.error("Hubo un problema al enviar el audio.");
            }
        } catch (error) {
            console.error("Error al procesar el audio:", error);
            toast.error("No se pudo procesar el audio.");
        }
    };

    const handleCancelSearch = () => {
        setSearchResults([]);
        setCurrentResultIndex(0);
        setFormData({ abbreviation: "" });
      
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
      };
      
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "20px",
                width: "100%",
                padding: "20px",
            }}
        >
            <Container sx={{ flex: { xs: 1, md: 3 }, pb:4, width: "100%"}}>
                <RecordView onNewAudio={handleAudioMessage} />
            </Container>
            <Box sx={{ flex: { xs: "1", md: 7 }, width: "100%" }}>
                <Container maxWidth="md">
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 1, 
                        }}
                    >
                        <TextField
                            label="Buscar"
                            name="abbreviation"
                            value={formData.abbreviation}
                            onChange={handleInputChange}
                            variant="outlined"
                            size="small"
                            slotProps={{
                                input: {
                                endAdornment: (
                                    <>
                                        {searchResults.length > 0 && (
                                            <>
                                                <IconButton size="small" onClick={handlePreviousResult}>
                                                    <NavigateBeforeIcon/>
                                                </IconButton>
                                                <IconButton size="small" onClick={handleNextResult}>
                                                    <NavigateNextIcon/>
                                                </IconButton>
                                                
                                                <IconButton size="small" onClick={handleCancelSearch}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </>
                                ),
                                },
                            }}
                        />

                        <DateRangeCalendar
                            onDateChange={(range) => {
                                setInitDate(new Date(range.startDate));
                                setEndDate(new Date(range.endDate));
                            }}
                        />
                        <IconButton aria-label="search" onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Container>
                

                <ChatUI
                    messages={messages}
                    searchResults={searchResults}
                    currentResultIndex={currentResultIndex}
                    onNextResult={handleNextResult}
                    onPreviousResult={handlePreviousResult}
                    onSendMessage={handleNewMessage}
                    isTyping={!!messages.find((msg) => msg.id === typingMessageId)}
                    chatEndRef={chatEndRef}
                />

            </Box>
        </Box>
    );
}
