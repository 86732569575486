import { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import { FileDropzone } from "../../components/Dropzone";
import { uploadAssistantFile } from "../../api/request";
import { toast } from 'react-toastify';

export function UploadKnowledge() {
  const [isLoading, setIsLoading] = useState(false);

  const extensionFiles = { 
    'application/pdf': ['.pdf'],
  };

  const handleIsLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleFileUpload = async (files) => {
    try {
      const formData = new FormData();
      const userId = localStorage.getItem('user_id');

      if (!userId) {
        throw new Error('User ID no encontrado');
      }

      formData.append('userId', userId);

      files.forEach((file, index) => {
        formData.append(`file_${index}`, file);
      });

      handleIsLoading(true);
      const response = await uploadAssistantFile(formData);

      if (response.status === 200) {
        toast.success("Pregúntale al asistente sobre los documentos cargados");
        console.log('Archivos subidos con éxito', response);
      } else {
        toast.error('Error en la subida de archivos');
        console.error('Error en la subida de archivos', response);
      }
    } catch (error) {
      toast.error('Error al cargar los archivos');
      console.error('Error al cargar los archivos', error);
    } finally {
      handleIsLoading(false);
    }
  };


  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '50px 0 50px 0' }}>

      <Stack spacing={2}>
        <Typography variant="h5" component="h2" style={{ color: "#0d3672", textAlign: "center" }}>
          Seleccione los archivos PDF
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", marginTop: "-2px", color: "#bdbac2" }}>
          Carga tus documentos PDF aquí para que el asistente pueda responder preguntas específicas relacionadas con su contenido.
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", marginTop: "-2px", color: "#bdbac2" }}>
          Formato PDF | Peso máximo 75MB
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '50px 0 50px 0' }}>
          <div style={{ border: "1px dashed #0d3672 ", borderRadius: "10px", borderWidth: "1px", marginLeft: "70px", marginRight: "70px", borderColor: "#0d3672", padding: "7px" }}>
            <FileDropzone onUpload={handleFileUpload} handleIsLoading={handleIsLoading} isLoading={isLoading} maxFiles={5} extensionFiles={extensionFiles} />
          </div>
        </div>
      </Stack>
    </div>
  );
}
