import axios from 'axios';
import { CustomRoutes } from '../routes';

// Configurar un cliente Axios con el token incluido
const apiClient = axios.create({
  baseURL: CustomRoutes.api_url_base,
});

// Interceptor para incluir el token en cada solicitud
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// Función para iniciar sesión
export async function login(data) {
  try {
    const response = await axios.post(
      `${CustomRoutes.api_url_base + CustomRoutes.api_login}`,
      data
    );
    return response.data;
  } catch (e) {
    return e.response;
  }
}

// Función para convertir audio a texto 
export async function audioToText(audioFormData) {
  try {
    let userId = localStorage.getItem('user_id');

    audioFormData.append('userId', userId);

    const response = await apiClient.post(
      CustomRoutes.api_audio,
      audioFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}

// Función para procesar texto
export async function processText(formData) {
  try {
    const userId = localStorage.getItem('user_id');

    formData.append('userId', userId);

    const response = await apiClient.post(
      CustomRoutes.api_process_text,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'json',
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}

// Función para obtener mensajes del usuario
export async function getUserMessages() {
  try {
    const userId = localStorage.getItem('user_id');
    const response = await apiClient.get(`${CustomRoutes.api_get_user_messages}/${userId}`);
    return response.data;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }

    // Lanza una excepción en caso de respuesta HTTP con error
    throw new Error(`Error ${e.response.status}: ${e.response.statusText}`);
  }
}

//Sirve para cargar archivos PDF al asistente de OpenAI
export async function uploadAssistantFile(formData) {
  try {

    const response = await apiClient.post(
      CustomRoutes.api_upload_vector_file,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'json',
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}

// Deuelve todas las abreviaciones y su palabra completa
export async function getAllTerms() {
  try {
    const response = await apiClient.get(`${CustomRoutes.api_get_terms}`);
    return response.data;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }

    throw new Error(`Error ${e.response.status}: ${e.response.statusText}`);
  }
}

// Agrega un nuevo termino
export async function postTerm(data) {
  try {
    const response = await apiClient.post(
      CustomRoutes.api_add_term,
      data,
      {
        headers: {
          'Content-Type': 'application/json', 
        },
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}

// Función para actualizar un término existente
export async function updateTerm(termId, data) {
  try {
    const response = await apiClient.put(
      `${CustomRoutes.api_put_term}/${termId}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}

// Función para eliminar un término existente
export async function deleteTerm(termId) {
  try {
    const response = await apiClient.delete(
      `${CustomRoutes.api_delete_term}/${termId}`,
      {
        headers: {
          'Content-Type': 'application/json', 
        },
      }
    );
    return response;
  } catch (e) {
    if (!e.response) {
      throw new Error("No se pudo conectar con el servidor");
    }
    return e.response;
  }
}
