import React, { useState, useRef, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TextField, Box, InputAdornment } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PropTypes from 'prop-types';

export const DateRangeCalendar = ({ onDateChange }) => {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const calendarRef = useRef(null);

  const toggleCalendar = () => {
    setIsCalendarVisible((prev) => !prev);
  };

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    if (!isSelectingEndDate) {

      setSelectionRange((prev) => {
        const updatedRange = { ...prev, startDate };
        
        // Si startDate es mayor que endDate, vaciar endDate
        if (prev.endDate && startDate > prev.endDate) {
          updatedRange.endDate = null;
        }

        // Notificar al componente padre con el nuevo rango
        if (onDateChange) onDateChange(updatedRange);
        return updatedRange;
      });
      setIsSelectingEndDate(true);
    } else {
      // Modificar solo endDate
      setSelectionRange((prev) => {
        const updatedRange = { ...prev, endDate };

        if (onDateChange) onDateChange(updatedRange);
        return updatedRange;
      });
      setIsSelectingEndDate(false);
      toggleCalendar();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarVisible(false);
        setIsSelectingEndDate(false);
      }
    };

    if (isCalendarVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarVisible]);

  const displayDate = `${selectionRange.startDate.toISOString().split('T')[0]} - ${
    selectionRange.endDate ? selectionRange.endDate.toISOString().split('T')[0] : ''
  }`;

  return (
    <Box position="relative">
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "40px",
          },
          "& .MuiInputBase-input": {
            padding: "8px 12px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0d3672",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0d3672",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0d3672",
          },
        }}
        fullWidth
        label="Rango de Fechas"
        value={displayDate}
        onClick={toggleCalendar}
        readOnly
        variant="outlined"
        slotProps={{
          input: {
            style: {
              fontSize: "0.875rem",
            },
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          },
        }}
      />
      {isCalendarVisible && (
        <Box ref={calendarRef} position="absolute" zIndex="modal" bgcolor="#fff">
          <DateRange
            ranges={[selectionRange]}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
          />
        </Box>
      )}
    </Box>
  );
};

DateRangeCalendar.propTypes = {
  onDateChange: PropTypes.func.isRequired,
};