import React from 'react';
import {  Container, Paper  } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import Recommendations from '../../components/Dashboard/Recomendations';
import Alerts from '../../components/Dashboard/AlertsComponent';
import CriticalVariablesChart from '../../components/Dashboard/Graphic';
import Grid from '@mui/material/Grid2';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export const Dashboard = () => {
  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {/* Recomendaciones */}
        <Grid size={{ xs: 12, md: 6 }}>
          <Item style={{height:"100%"}}>
            <Recommendations
              recommendations={[
                { id:1,icon: 'up', title: 'Presión', description: 'Subirla hasta el punto de eficiencia de 150 psi.' },
                { id:2,icon: 'up', title: 'Velocidad', description: 'Aumentar gradualmente hasta llegar a 150 PSI.' },
                { id:3,icon: 'down', title: 'Temperatura', description: 'Disminuir temperatura del aceite.' },
              ]}
            />
          </Item>
        </Grid>

        {/* Alertas */}
        <Grid size={{xs:12, md:6}}>
          <Item style={{height:"100%"}}>
            <Alerts
              alerts={[
                { id:1,name: 'Caudal alto', onAction: (action) => console.log('Caudal alto:', action) },
                { id:2,name: 'Vibración', onAction: (action) => console.log('Vibración:', action) },
                { id:3,name: 'Presión', onAction: (action) => console.log('Presión:', action) },
              ]}
            />
          </Item>
        </Grid>

        {/* Gráfico */}
        <Grid size={{xs:12, md:12}}>
          <Item>
            <CriticalVariablesChart />
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

