import * as React from "react";
import { Box, Button, CssBaseline, TextField, Typography, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { login } from "../../api/request";
import { useNavigate } from "react-router-dom";
import { CustomRoutes } from "../../routes";
import { toast } from 'react-toastify';
import backgroundImage from "../../assets/images/login_digital_assistant.png"
import logoCenitLogin from "../../assets/images/logo_cenit_login.png"

export const Login = () => {
  const [formValues, setFormValues] = React.useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await login(formValues);

      if (response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("user_id", response.user_id);
        toast.success("Login success");
        navigate(CustomRoutes.app_digital_assistant);
      } else {
        toast.error(response?.data?.error || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("An unexpected error occurred", error);
    }
  };

  React.useEffect(() => {
    document.title = "Inicio de sesión";
  }, []);

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        {/* Panel Izquierdo: Imagen */}
        <Box
          sx={{
            flex: 1,
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: { xs: "none", sm: "block" },
          }}
        />
        {/* Panel Derecho: Formulario */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "80%",
              maxWidth: 400,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

            <Box
              component="img" 
              src={logoCenitLogin} 
              alt="Logo Cenit"
              sx={{
                width: "230px", 
                height: "auto", 
                marginTop: "-2.5rem",

              }}
            />

            <Box sx={{marginBottom : "3rem", mt: 1, width: "100%", maxWidth: "380px" }}>

              <Typography component="h1" variant="h5" sx={{ mb: 2, fontWeight: "bold", textAlign: "center"}}>
                Asistente Digital
              </Typography>
              <Typography variant="body1"   sx={{
                marginTop:"-18px",
                fontSize: "0.9rem",
                color: "text.secondary",
                
              }}>
                  Ingresa tu correo electrónico y contraseña para continuar
              </Typography>
            </Box>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "100%", maxWidth: "380px" }}>
              <Typography variant="body1" style={{marginBottom:"-15px", fontSize: "0.9rem",}}>
                Correo electrónico
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                value={formValues.email}
                onChange={handleChange}
                autoFocus
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#B6B310", 
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B6B310",
                    },
                  },
                }}
              />
              <Typography variant="body1" style={{marginBottom:"-15px", marginTop:"20px", fontSize: "0.9rem",}}>
                Contraseña
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={formValues.password}
                onChange={handleChange}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#B6B310", 
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B6B310",
                    },
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#B6B310" }}
              >
                Ingresar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
