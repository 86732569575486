import React, { useEffect, useState, useMemo, useCallback  } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Avatar,
  Typography,
  Paper,
  Container,
  Fade,
  TextField,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import RobotAvatar from "../../assets/images/Robot.svg";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import Typewriter from "typewriter-effect";
import { TypingDotsComponent } from "../../utils/Animations/TypingDots";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "60vh",
  display: "flex",
  flexDirection: "column",
  borderRadius: 16,
  overflow: "hidden",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "20px",
  overflowY: "auto",
  backgroundColor: "#f5f5f5",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bdbdbd",
    borderRadius: "4px",
  },
}));

const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== "sent",
})(({ sent }) => ({
  display: "flex",
  alignItems: "flex-end",
  marginBottom: "16px",
  flexDirection: sent ? "row-reverse" : "row",
}));

const Message = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "sent",
})(({ sent }) => ({
  padding: "12px 16px",
  maxWidth: "70%",
  borderRadius: "16px",
  backgroundColor: sent ? "#10486D" : "#fff",
  color: sent ? "#fff" : "#000",
  marginLeft: sent ? "0" : "8px",
  marginRight: sent ? "8px" : "0",
  position: "relative",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
  },
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  borderTop: "1px solid #e0e0e0",
  backgroundColor: "#fff",
}));

export const ChatUI = ({
  messages = [],
  searchResults = [],
  currentResultIndex = 0,
  onSendMessage,
  isTyping,
  chatEndRef,
}) => {
  const userId = localStorage.getItem("user_id");
  const [newMessage, setNewMessage] = useState("");
  const [currentAnimatedMessageId, setCurrentAnimatedMessageId] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // Desplazarse hacia el mensaje actual en los resultados de búsqueda
  useEffect(() => {
    if (searchResults.length > 0 && currentResultIndex < searchResults.length) {
      const messageId = searchResults[currentResultIndex]?.id;
      const element = document.getElementById(messageId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [searchResults, currentResultIndex]);

  // Identificar el último mensaje con audio
  const lastMessageWithAudio = useMemo(() => {
    return messages.slice().reverse().find((msg) => msg.audio_base64);
  }, [messages]);

  // Desplazarse hacia el final cuando hay nuevos mensajes o `isTyping` cambia
  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      chatEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
  }, [chatEndRef]);

  useEffect(() => {
    if (!isSmallScreen) {
      scrollToBottom();
    }
  }, [isSmallScreen, scrollToBottom, isTyping, messages]);

  useEffect(() => {
    // Animar solo el último mensaje
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (!lastMessage.sent && lastMessage.text?.trim()) {
        setCurrentAnimatedMessageId(lastMessage.id);
      } else {
        setCurrentAnimatedMessageId(null);
      }
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const message = {
        created_at: new Date().toISOString(),
        id: `${userId}-${Date.now()}-${Math.random()}`,
        sent: true,
        text: newMessage.trim(),
      };

      onSendMessage(message);
      setNewMessage("");
    }
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return "";

    const date = new Date(timestamp);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("es-ES", options).format(date);
  };

  const renderedMessages = useMemo(
    () =>
      messages
        .filter((message) => message.text?.trim())
        .map((message, index) => {
          const isHighlighted =
            searchResults.findIndex((result) => result.id === message.id) !== -1;
          return (
            <Fade in={true} key={message.id} timeout={500}>
              <MessageBubble sent={message?.sent} id={message.id}>
                <Avatar style={{ backgroundColor: message?.sent ? "" : "#10486D" }}>
                  {message?.sent ? (
                    <PersonIcon />
                  ) : (
                    <Box
                      component="img"
                      src={RobotAvatar}
                      alt="Contact"
                      sx={{ width: 30, height: 30 }}
                    />
                  )}
                </Avatar>
                <Message
                  sent={message?.sent}
                  sx={{
                    border: isHighlighted ? "2px solid #ff9800" : "none",
                  }}
                >
                  <Typography variant="body1" component="span" sx={{ wordBreak: "break-word" }}>
                    {message.id === currentAnimatedMessageId ? (
                      <Typewriter
                        options={{
                          strings: message.text,
                          autoStart: true,
                          delay: 30,
                          onComplete: () => setCurrentAnimatedMessageId(null),
                        }}
                      />
                    ) : (
                      message.text
                    )}
                  </Typography>
                  {/* Renderizar audios */}
                  {message.audio_base64 && (
                    <audio
                      controls
                      autoPlay={lastMessageWithAudio?.id === message.id}
                      src={`data:audio/mpeg;base64,${message.audio_base64}`}
                      style={{ marginTop: "10px", width: "100%" }}
                      onLoadedMetadata={(event) => {
                        event.target.playbackRate = 1.25;
                      }}
                    >
                      Tu navegador no soporta el elemento de audio.
                    </audio>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 0.5,
                      mt: 0.5,
                    }}
                  >
                    <Typography
                      variant="caption"
                      color={message?.sent ? "rgba(255,255,255,0.7)" : "text.secondary"}
                    >
                      {formatTime(message?.created_at)}
                    </Typography>
                  </Box>
                </Message>
              </MessageBubble>
            </Fade>
          );
        }),
    [messages, searchResults, currentAnimatedMessageId, lastMessageWithAudio]
  );

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <StyledPaper>
        <MessageContainer>
          {renderedMessages}
          {isTyping && (
            <MessageBubble sent={false}>
              <Avatar style={{ backgroundColor: "#10486D" }}>
                <Box component="img" src={RobotAvatar} sx={{ width: 30, height: 30 }} />
              </Avatar>
              <Message sent={false}>
                <TypingDotsComponent />
              </Message>
            </MessageBubble>
          )}
          <div ref={chatEndRef} />
        </MessageContainer>
        <InputContainer>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Escribe un mensaje..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
          />
          <IconButton
            color="primary"
            onClick={handleSendMessage}
            disabled={!newMessage.trim()}
            sx={{ ml: 1 }}
          >
            <SendIcon />
          </IconButton>
        </InputContainer>
      </StyledPaper>
    </Container>
  );
};

ChatUI.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
      sent: PropTypes.bool,
      created_at: PropTypes.string,
    })
  ),
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  currentResultIndex: PropTypes.number,
  onSendMessage: PropTypes.func.isRequired,
  isTyping: PropTypes.bool.isRequired,
  chatEndRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
};
