import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoIcon from '@mui/icons-material/Info';

const Recommendations = ({ recommendations }) => {
  const icons = {
    up: <ArrowUpwardIcon color="primary" />,
    down: <ArrowDownwardIcon color="secondary" />,
    info: <InfoIcon />,
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Recomendaciones
      </Typography>
      <List>
        {recommendations.map((rec, index) => (
          <ListItem key={rec.id}>
            <ListItemIcon>{icons[rec.icon]}</ListItemIcon>
            <ListItemText primary={rec.title} secondary={rec.description} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

Recommendations.propTypes = {
    recommendations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, 
        icon: PropTypes.oneOf(['up', 'down', 'info']).isRequired, 
        title: PropTypes.string.isRequired, 
        description: PropTypes.string.isRequired, 
      })
    ).isRequired, 
  };

export default Recommendations;
