import React from 'react';
import Chart from 'react-apexcharts';

const CriticalVariablesChart = () => {
  const chartOptions = {
    chart: {
      id: 'critical-variables',
      type: 'line',
    },
    xaxis: {
      categories: ['24 Oct', '28 Oct', '30 Oct', '02 Nov', '04 Nov', '07 Nov', '10 Nov', '16 Nov'],
    },
    yaxis: {
      min: 0,
      max: 210,
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 5,
    },
    title: {
        text: 'Variables Criticas',
        align: 'left'
      },
  };

  const chartSeries = [
    {
      name: 'Variable Crítica',
      data: [60, 90, 120, 180, 150, 90, 60, 120],
    },
  ];

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="line" height={300} />
    </div>
  );
};

export default CriticalVariablesChart;
