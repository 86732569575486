import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import { styled } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from '@mui/icons-material/Settings';
import { NestedList } from "./NestedList";
import Tooltip from "@mui/material/Tooltip";
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BotImage from "../../assets/images/bot.png"

import { CustomRoutes } from "../../routes";

const LinkBehavior = React.forwardRef((props, ref) => {
  return <NavLink ref={ref} to="/" {...props} />;
});

const CustomListButton = styled(ListItemButton)(() => {
  return {
     '& .MuiTypography-root': {
          fontSize: '0.85rem'
     },
     '&.active': { 
          backgroundColor: 'rgba(0, 0, 0, 0.04)' 
      }
  }
});

const listItemTextStyle = {
  marginLeft: "0px",
};

export const mainListItems = (drawerOpen) => (
  <React.Fragment>
    <CustomListButton component={LinkBehavior} to={CustomRoutes.app_digital_assistant}>
      <Tooltip title="Asistente Digital" disableInteractive>
        <ListItemIcon>
          <img
            src={BotImage}
            alt="Asistente Digital"
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Asistente Digital" />
    </CustomListButton>

    <CustomListButton component={LinkBehavior} to={CustomRoutes.app_dashboard}>
      <Tooltip title="Dashboard" disableInteractive>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Dashboard" />
    </CustomListButton>
    
    <NestedList 
      mainTitle="Administración" 
      mainIcon={SettingsIcon} 
      tooltip={"Administración"}
      drawerOpen={drawerOpen}
    >
      <CustomListButton component={LinkBehavior} to={CustomRoutes.app_upload_knowledge}>
        <Tooltip title="Conocimiento Base" disableInteractive>
          <ListItemIcon sx={{ pl: 1 }}>
            <LibraryBooksIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Conocimiento Base" />
      </CustomListButton>

      <CustomListButton component={LinkBehavior} to={CustomRoutes.app_terms_management}>
        <Tooltip title="Manejo de terminos" disableInteractive>
          <ListItemIcon sx={{ pl: 1 }}>
            <span className="material-symbols-outlined">
              match_word
            </span>
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Manejo de terminos" sx={listItemTextStyle} />
      </CustomListButton>
    </NestedList>
  </React.Fragment>
);

