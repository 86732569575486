import React from "react";
import { styled, keyframes } from "@mui/system";
import { Box } from "@mui/material";

const dotWidth = 10;
const dotColor = "#3b5998";
const speed = "1.5s";

const blink = keyframes`
  0% { opacity: 0.1; }
  20% { opacity: 1; }
  100% { opacity: 0.1; }
`;

const TypingDots = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: `${dotWidth * 1.5}px`,
  height: `${dotWidth}px`,

  "& span": {
    width: `${dotWidth}px`,
    height: `${dotWidth}px`,
    backgroundColor: dotColor,
    borderRadius: "50%",
    animation: `${blink} ${speed} infinite`,
  },

  "& span:nth-of-type(2)": {
    animationDelay: "0.2s",
  },

  "& span:nth-of-type(3)": {
    animationDelay: "0.4s",
  },
}));

export const TypingDotsComponent = () => (
  <TypingDots>
    <span></span>
    <span></span>
    <span></span>
  </TypingDots>
);

