import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout } from "./components/Layout/Layout";
import { VirtualAssistant } from "./pages/VirtualAssistant/VirtualAssistant";
import { UploadKnowledge } from "./pages/Admin/UploadKnowledge";
import { TermsManagement } from "./pages/Admin/TermsManagement";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { CustomRoutes } from "./routes";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />} />{" "}

        <Route
          path={CustomRoutes.app_digital_assistant}
          element={
            <ProtectedRoute>
              <Layout title="Asistente Digital">
                <VirtualAssistant />
              </Layout>
            </ProtectedRoute>
          }
        />{" "}

        <Route
          path={CustomRoutes.app_upload_knowledge}
          element={
            <ProtectedRoute>
              <Layout title="Administración">
                <UploadKnowledge />
              </Layout>
            </ProtectedRoute>
          }
        />{" "}

        <Route
          path={CustomRoutes.app_dashboard}
          element={
            <ProtectedRoute>
              <Layout title="Dashboard">
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />{" "}

        <Route
          path={CustomRoutes.app_terms_management}
          element={
            <ProtectedRoute>
              <Layout title="Dashboard">
                <TermsManagement />
              </Layout>
            </ProtectedRoute>
          }
        />{" "}

      </Routes>

      <ToastContainer />
    </HashRouter>
  );
}

export default App;
