import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export function FileDropzone({ onUpload, handleIsLoading, isLoading, maxFiles, extensionFiles }) {
  // Estado para manejar Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    useFsAccessApi: false,
    maxFiles: maxFiles,
    accept: extensionFiles,
    maxSize: 75000000, // 75MB
    onDropRejected: (rejectedFiles) => {
      let message = '';
      let severity = 'error';

      if (rejectedFiles.length > 0) {
        const isMaxFilesRejected = rejectedFiles.some(file => file.errors[0].code === 'too-many-files');
        const isMaxSizeRejected = rejectedFiles.some(file => file.errors[0].code === 'file-too-large');

        if (isMaxFilesRejected) {
          message = `Solo se permiten un máximo de ${maxFiles} archivos`;
        } else if (isMaxSizeRejected) {
          message = 'El tamaño máximo permitido son 75Mb';
        } else {
          message = rejectedFiles.map(file => `${file.file.name}: Archivo no válido`).join(', ');
        }
      }

      setSnackbar({ open: true, message, severity });
    }
  });

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const handleSubmit = () => {
    handleIsLoading(true);
    onUpload(acceptedFiles);
  };

  return (
    <section className="container" style={{padding:"0px 20px"}}>
      <div style={{ textAlign: "center", width: `${isDragActive ? "120%" : "100%"}` }} {...getRootProps({ className: `dropzone ${isDragActive ? 'active' : ''} ${isDragReject ? 'reject' : ''}` })}>
        <input {...getInputProps()} />
        <p>
          {isDragActive ? 'Suelta los archivos aquí...' : 'Arrastra y suelta algunos archivos aquí, o haz clic para seleccionar archivos'}
          {isDragReject && ' - Solo se permiten archivos .pdf y un máximo de 5 archivos'}
        </p>
      </div>
      <aside>
        {files.length > 0 && (
          <>
            <div>
              <h4>Sus Archivos</h4>
              <ul style={{ padding: "20px" }}>{files}</ul>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "0px 40%" }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" startIcon={<UploadFileIcon />} style={{ backgroundColor: "#0d3672", width: "100%", minWidth: "100px" }} onClick={handleSubmit}>
                  Enviar
                </Button>
              )}
            </div>
          </>
        )}
      </aside>
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }} >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </section>
  );
}
