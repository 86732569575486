import { useState, useEffect, useRef, useCallback  } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./Style.css";

export const RecordView = ({ onNewAudio }) => {
  const recorderControls = useVoiceVisualizer();
  const { recordedBlob, error, isRecordingInProgress, isCleared } = recorderControls;

  const [showVisualizer, setShowVisualizer] = useState(true);

  const containerRef = useRef(null);

  const resetCanvasHeight = useCallback(() => {
    if (containerRef.current) {
      const canvas = containerRef.current.querySelector("canvas");
      if (canvas) {
        if (isRecordingInProgress) {
          canvas.style.height = "200px";
          canvas.style.transition = "height 0.3s ease";
        } else if (!isRecordingInProgress && isCleared) {
          canvas.style.height = "0px";
          canvas.style.transition = "height 0.3s ease";
        }
      }
    }
  }, [isRecordingInProgress, isCleared]);

  useEffect(() => {
    if (error) {
      console.error("Error en la grabación:", error);
    }
  }, [error]);

  useEffect(() => {
    resetCanvasHeight();
  }, [isRecordingInProgress, isCleared, resetCanvasHeight]);

  useEffect(() => {
    const handleResize = () => {
      setShowVisualizer(false);
      setTimeout(() => {
        setShowVisualizer(true);
      }, 0);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (showVisualizer) {
      resetCanvasHeight();
    }
  }, [showVisualizer, resetCanvasHeight]);

  const handleSendAudio = () => {
    if (recordedBlob) {
      onNewAudio(recordedBlob);
    }
  };

  return (
    <div className="container" style={{ height: "100%", backgroundColor: "#f5f5f5", borderRadius: "5%" }}>
      <h2 className={!isRecordingInProgress && isCleared ? "adjust-title" : "title"}>
        Asistente Digital
      </h2>
      <div className="recording-container">
        {!isRecordingInProgress && isCleared && (
          <Typography
            className="subtitle"
            variant="body2"
            color="textSecondary"
            style={{ textAlign: "center", color: "#bdbac2" }}
          >
            Haga clic en el botón para comenzar a grabar
          </Typography>
        )}

        {/* Visualizador */}
        <div ref={containerRef}>
          {showVisualizer && (
            <VoiceVisualizer
              controls={recorderControls}
              isDefaultUIShown={false}
              isProgressIndicatorShown={false}
              mainBarColor="#66e162"
            />
          )}
        </div>

        <div className="send-audio-container">
          {recordedBlob && (
            <div className="send-audio">
              <Button
                onClick={handleSendAudio}
                variant="contained"
                style={{ backgroundColor: "#10486D", marginTop: "20px" }}
              >
                Consultar
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

RecordView.propTypes = {
  onNewAudio: PropTypes.func.isRequired,
};

export default RecordView;
