import React from 'react';
import { List, ListItem, ListItemText, IconButton, Typography, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PropTypes from 'prop-types';
const Alerts = ({ alerts }) => {
    return (
        <div>
          <Typography variant="h6" gutterBottom>
            Alertas
          </Typography>
          <List>
            {alerts.map((alert, index) => (
                <Box
                key={alert.id}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fdf7f8', 
                    borderRadius: '8px', 
                    px: 1.5, // Espaciado interno
                    marginBottom: '12px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', 
                }}
                >
                <ListItem
                    disableGutters
                    secondaryAction={
                        <>
                          <IconButton edge="end" onClick={() => alert.onAction('search')}>
                            <SearchIcon />
                          </IconButton>
                          <IconButton edge="end" onClick={() => alert.onAction('details')}>
                            <AccessTimeIcon />
                          </IconButton>
                        </>
                      }
                >
                    <NotificationsActiveIcon color="error" style={{ marginRight: 8 }} />
                    <ListItemText primary={alert.name} />
                </ListItem>
                </Box>
            ))}
          </List>
        </div>
      );
    };


    Alerts.propTypes = {
        alerts: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, 
            name: PropTypes.string.isRequired, 
            onAction: PropTypes.func.isRequired,
          })
        ).isRequired, 
      };

export default Alerts;
